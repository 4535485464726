









































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

import FooterBlock from '@/components/FooterBlock.vue'
import ContactForm from '@/components/ContactForm.vue'
import DemoCard from './DemoCard.vue'

@Component({
  components: {
    FooterBlock,
    ContactForm,
    DemoCard
  },
})
export default class GetDemoBlock extends Vue {

  activeDemoIndex = 0

  demos = [
    {
      name: '咖啡廳',
      img: 'https://spics.wantu.cn/21f8e5381ca0fdb5af3eedb7ed12ef92.png',
    },
    {
      name: '茶餐廳',
      img: 'https://spics.wantu.cn/a9ee9be0860f69ff3728ee4ea740408e.png',
    },
    {
      name: '米線店',
      img: 'https://spics.wantu.cn/a29d514c09206650ee903261767f4bdf.png',
    }
  ]

  get isH5() {
    return AppModule.isH5
  }
}
