

















import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

import GetDemoBlock from './components/GetDemoBlock.vue'
import DemoShowBlock from './components/DemoShowBlock.vue'

@Component({
  components: {
    GetDemoBlock,
    DemoShowBlock
  },
})
export default class GetDemo extends Vue {

  isShowGetDemo = true

  isShowDemo = false

  get isH5() {
    return AppModule.isH5
  }

  showDemo() {
    if (!this.isH5) {
      this.isShowGetDemo = false
      this.isShowDemo = true
    } else {
      this.$router.push({
        name: 'demoshow'
      })
    }
  }
}
