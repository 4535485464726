

























































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

import FooterBlock from '@/components/FooterBlock.vue'
import ContactForm from '@/components/ContactForm.vue'
import DemoShowCard from './DemoShowCard.vue'
import DemoShowCardLarge from './DemoShowCardLarge.vue'
import ContactWays from '@/components/ContactWays.vue'

@Component({
  components: {
    FooterBlock,
    ContactForm,
    DemoShowCard,
    DemoShowCardLarge,
    ContactWays
  },
})
export default class DemoShowBlock extends Vue {

  curDemo = {}

  isShowDemoModal = false

  get demos() {
    return AppModule.demos
  }

  get isH5() {
    return AppModule.isH5
  }

  showDemoModal(demo) {
    console.log('click demo')
    this.curDemo = demo
    this.isShowDemoModal = true
  }
}
