import { render, staticRenderFns } from "./ContactWays.vue?vue&type=template&id=52e3aeb3&scoped=true&"
import script from "./ContactWays.vue?vue&type=script&lang=ts&"
export * from "./ContactWays.vue?vue&type=script&lang=ts&"
import style0 from "./ContactWays.vue?vue&type=style&index=0&id=52e3aeb3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52e3aeb3",
  null
  
)

export default component.exports