








import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
import ContactWay from './ContactWay.vue'

@Component({
  components: {
    ContactWay
  }
})
export default class ContactWays extends Vue {
  get isH5() {
    return AppModule.isH5
  }
}
